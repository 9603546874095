// Import Node Modules
import React from 'react';

// renderPage
import { renderPage } from '../../template/RenderPage';

// TempData
import Data from '../../mocks/pages/PageData/FAQ_data';

// Class
const FAQ = () => <div>{renderPage(Data)}</div>;

export default FAQ;
