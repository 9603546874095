// Import Images
import BannerImage from '../../../assets/Images/CR_lokation_10.png';

// TempData
import TempDataNavBar from '../TempData_NavBar';
import TempDataMegaMenu from '../TempData_MegaMenu';
import TempDataFooter from '../TempData_footer';

// Page Data
const Data = {
  pageName: 'FAQ Test Page',
  pageTemplate: 'Template3',
  pageData: {
    nav: TempDataNavBar,
    megaMenu: TempDataMegaMenu,
    footer: TempDataFooter,
    level_1_Id: 'Page_1',
    pageTitle: 'FrontPageComponentTest',
    pageDescription: 'Tester components på frontpage',
    bannerImage: BannerImage, // accepts null
    bannerContent: '<h1>Banner text accepts HTML</h1>',
    historyBack: true,
    // bannerContent: null,
    bottomContent: [],
    desktopTools: {}, // accepts null
  },
  pageContent: [
    // CollapsibleFAQ
    {
      componentName: 'CollapseableFAQ',
      componentData: {
        header: 'CollapsibleFAQ',
        faqs: [
          {
            id: 'first',
            header: 'CollapsibleFAQ Options',
            text: 'Header - plain text<br />Manchet - accepts HTML',
          },
          {
            id: 'second',
            header: 'Buttom Margin',
            text:
              'Mobile - 10px<br />Tablet Portrait - 20px<br />Tablet Landscape - 40px<br />Desktop: 60px',
          },
        ],
      },
    },
  ],
};

export default Data;
